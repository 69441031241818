import prismicClient, { prismicLinkToNavigationLink } from './prismicClient'
import { INavigationLink } from './types'

const loadFanServices = async (): Promise<INavigationLink[]> => {
  const prismicUid = 'fan_services'
  const data = await prismicClient.getSingle(prismicUid)

  const fanServices = await Promise.all(
    data.data.links.map(
      async (link: any) => await prismicLinkToNavigationLink(prismicUid, link)
    )
  )

  return fanServices
}

export default loadFanServices
