import { INavigationLink } from './types'

// TODO
// const loadSheduleSearchShortcutsOnline = async (): Promise<IMainNavigation> => {
//   const data = await prismicClient.getSingle('schedule_shortcuts');
// }

// Static dummy (copy & paste from `loadSheduleSearchShortcutsOnline` result)
//
const loadScheduleSearchShortcutsDummy = async (): Promise<
  INavigationLink[]
> => {
  return Promise.resolve([
    {
      id: 'topspiele-des-tages',
      href: '#',
      name: 'Topspiele des Tages'
      // title: 'Topspiele des Tages'
    },
    {
      id: 'deutschland-cup',
      href: '#',
      name: 'Deutschland-Cup'
      // title: 'Deutschland-Cup'
    }
  ])
}

// switch between static dummy navigation and `online` version from prismic api
// online version is not production ready, still needs caching!
//
// export { loadSheduleSearchShortcutsOnline as loadScheduleSearchShortcuts }
export { loadScheduleSearchShortcutsDummy as loadScheduleSearchShortcuts }
